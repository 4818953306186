<template>
  <div>
    <multiselect
      :id="id"
      ref="multiselect"
      v-model="selected_group"
      :placeholder="placeholder"
      :open-direction="openDirection"
      :options="groups"
      :multiple="false"
      :searchable="true"
      :preselect-first="true"
      :preserve-search="true"
      :loading="is_loading"
      :internal-search="false"
      :close-on-select="true"
      :options-limit="50"
      :show-labels="false"
      :limit-text="limitText"
      :custom-label="customLabel"
      :allow-empty="false"
      track-by="name"
      label="name"
      @search-change="asyncFind"
    >
      <template
        slot="option"
        slot-scope="props"
      >
        <div class="option d-flex">
          <div class="option__icon">
            <font-awesome-icon
              v-if="props?.option?.id === value?.id"
              :icon="['far', 'check']"
            />
          </div>
          <div class="option__name ml-1">
            {{ customLabel(props.option) }}
          </div>
        </div>
      </template>
      <span slot="noResult">No groups found.</span>
    </multiselect>
  </div>
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";
import NetworkModule from "../../lib/NetworkModule";
import {excluded} from "vee-validate/dist/rules.esm.js";

export default {
  components: {
    Multiselect,
  },

  props: {
    openDirection: {type: String, required: false, default: "auto"},
    value: {type: Object, required: false, default: undefined},
    groupUrl: {type: String, required: false, default: undefined},
    groupList: {type: Array, required: false, default: undefined},
    id: {type: String, required: false, default: "group_select"},
    hint: {type: String, required: false, default: undefined},
    filters: {type: Object, required: false, default: undefined},
    placeholder: {type: String, required: false, default: "Search for a group"},
    exclude: {type: Array, required: false, default: () => []},
  },

  data() {
    return {
      groups: [],
      is_loading: false,
      request_autocomplete_groups: new NetworkModule.SingleCancelRequest(),
    };
  },

  computed: {
    selected_group: {
      get() {
        return this.value;
      },
      set(group) {
        this.groupSelect(group);
      },
    },
  },

  watch: {
    hint() {
      this.asyncFind(this.hint);
    },
  },

  mounted() {
    this.asyncFind();
  },

  methods: {
    limitText(count) {
      return `and ${count} other groups`;
    },

    asyncFind(query = "") {
      if (query === "group_select") {
        query = "";
      }
      this.is_loading = true;

      if (this.groupList) {
        this.groups = this.groupList;
        this.is_loading = false;
        return;
      }

      let params = {};
      if (this.filters) {
        params = this.filters;
      }
      if (query) {
        params.term = query;
      }
      const get_params = Object.entries(params)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join("&");

      const url_base = this.groupUrl
        ? this.groupUrl
        : `${this.$store.state.settings.apiBase}/business/autocomplete/list-groups`;
      const param_str = get_params;
      var group_url = url_base;
      if (param_str.length > 0) {
        group_url = `${group_url}?${param_str}`;
      }

      this.request_autocomplete_groups.get(group_url, parsed_json => {
        if (this.exclude.length > 0) {
          this.groups = parsed_json.filter(item => !this.exclude.includes(item.uuid));
        } else {
          this.groups = parsed_json;
        }
        this.is_loading = false;

        if (query == this.hint) {
          this.$refs.multiselect.updateSearch(query);
          this.groupSelect(parsed_json[0]);
        }
      });
    },

    customLabel(data) {
      return data.name;
    },

    groupSelect(group) {
      this.$emit("input", group);
    },
  },
};
</script>
